import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import MainContext from 'MainContext';
import { StyledImage } from 'components/wrapper';
import Profile from 'asset/profile.svg';

const Wrapper = styled.div`
  position: relative;
  height: 4.0864vw;
  width: 100%;
  font-size: 0.728vw;
  font-weight: 500;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textSecondary};
  border-bottom: 1px solid ${(props) => props.theme.borderBackground};
  transition: 200ms linear;
  &:hover {
    cursor: pointer;
  }
`;

const Circle = styled.div`
  width: 2.342vw;
  height: 2.342vw;
  margin-right: 0.624vw;
  margin-left: 0.832vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 300ms linear;
  background-color: ${(props) => props.theme.borderBackground};
`;

const DefaultProfile = styled.img.attrs({
  src: Profile,
})`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfileName = styled.h3`
  margin: 0;
  font-size: 0.9vw;
`;

const ProfileEmail = styled.p`
  margin: 0;
  font-size: 0.7vw;
  color: ${(props) => props.theme.textSecondary};
`;

const App = (props) => {
  const { theme } = useContext(ThemeContext);
  const { profile } = useContext(MainContext).login;

  return (
    <Wrapper theme={theme} display={props.selectedTab === props.link}>
      <a style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: " 0.728vw", textDecoration: "none", color: theme.textSecondary, fontWeight: "500" }} target="_blank" href={`https://${profile.business_name}.3dlayers.app/profile`} rel="noreferrer">
        <Circle theme={theme}>
          {profile.profile_picture ? (
            <StyledImage
              src={`${process.env.REACT_APP_STATISTIC_SERVICE_URL}/avatar/${profile.profile_picture}`}
            />
          ) : <DefaultProfile />}
        </Circle>
        <div>
          <ProfileName>{profile.first_name + ' ' + profile.last_name}</ProfileName>
          <ProfileEmail>{profile.cellphone}</ProfileEmail>
        </div>
      </a>
    </Wrapper>
  );
};

export default App;
