import React, { useState, createContext } from 'react';

// Create Context Object
export const CounterContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const CounterContextProvider = (props) => {
  const [cellphone, setCellphone] = useState('');
  const [showSidBar, setShowSidBar] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [navBarPageTitle, setNavBarPageTitle] = useState('');
  const [history, setHistory] = useState([]);
  const [nextHistory, setNextHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [navigationBar, setNavigationBar] = useState([]);
  const [showBasket, setShowBasket] = useState(false);

  return (
    <CounterContext.Provider
      value={{
        cellphone,
        setCellphone,
        showSidBar,
        setShowSidBar,
        showDrawer,
        setShowDrawer,
        selectedTab,
        setSelectedTab,
        navBarPageTitle,
        setNavBarPageTitle,
        history,
        setHistory,
        nextHistory,
        setNextHistory,
        navigationBar,
        setNavigationBar,
        showBasket,
        setShowBasket,
      }}
    >
      {props.children}
    </CounterContext.Provider>
  );
};
