import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.LOGIN_STATE:
      return { ...state, loginState: action.payload };
    case types.SELECTED_HUB_MODE_ID:
      return { ...state, selectedHUbModeId: action.payload };
    case types.SELECTED_HUB_MODE_TYPE:
      return { ...state, selectedHUbModeType: action.payload };
    case types.SET_PROFILE:
      return { ...state, profile: action.payload };
    case types.SET_TOKEN:
      return { ...state, token: action.payload };
    case types.SET_SOCKET:
      return { ...state, socket: action.payload };
    case types.SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case types.SET_CONVERSATION_NOTIFICATIONS:
      return { ...state, conversationsNotifications: action.payload };
    case types.NEW_NOTIFICATION_RECIEVED:
        let newNotifications=[...state.notifications,action.payload];
        return { ...state , notifications:newNotifications};
    case types.SET_EMAIL:
      return { ...state, email: action.payload };
    case types.SET_REDIRECT:
      return { ...state, redirect: action.payload };
    case types.SET_ROLES:
      return { ...state, roles: action.payload };
    case types.SET_SELECTED_ROLES:
      return { ...state, selectedRole: action.payload };
    case types.SET_FAMILY_SIZE:
      return { ...state, familySize: action.payload };
    case types.SET_USED_SIZE:
      return { ...state, usedSize: action.payload };
    case types.SET_FAMILY_CURRENCY:
      return { ...state, familyCurrency: action.payload };
    case types.SET_FAMILY_TYPE:
      return { ...state, familyType: action.payload };

    default:
      return state;
  }
};

export const key = 'login';
export default reducer;
