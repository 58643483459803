import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import MainContext from '../../MainContext';

import { updateUserProfile } from 'containers/login/container/api';
import { createMaterial, createColor } from 'containers/materials/container/api';
import { createLocation, createPaymentMethod } from 'containers/settings/container/api';

import Modal from 'components/modal';

import Hello from './components/hello';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import Step4 from './components/step4';
import Step5 from './components/step5';
import Step6 from './components/step6';
import Stepper from './components/stepper';

import { validateBuisnessName } from 'utils/validators';

const Wrapper = styled.div`
  position: relative;
  width: 39.979vw;
  height: 39.823vw;
  display: ${(props) => (props.open ? 'block' : 'none')};
  overflow: hidden;
  border-top-left-radius: 2.08vw;
  border-top-right-radius: 2.08vw;
`;

function Boarding(props) {
  const {
    boardingState,
    boardingOpen,
    boardingFirstLaunch,
    setBoardingState,
    setBoardingOpen,
    setBoardingFirstLaunch,
  } = useContext(MainContext).boarding;
  const { profile, getUserProfileContext, familyType, selectedRole } =
    useContext(MainContext).login;
  const { updateToasterContext } = useContext(MainContext).toasters;

  const [businessName, setBusinessName] = useState('');
  const [technology, setTechnology] = useState('');
  const [materialType, setMaterialType] = useState('time');
  const [materialId, setMaterialId] = useState('');
  const [materialName, setMaterialName] = useState('');
  const [price, setPrice] = useState(0);
  const [locationName, setLocationName] = useState('');
  const [paymentMethodName, setPaymentMethodName] = useState('');


  useEffect(() => {
    if (profile.boarding_state < 5.5 && !!profile.boarding_state && !selectedRole && window.location.pathname.includes('dashboard') ) {
      setBoardingOpen(true);
      if (profile.boarding_state !== 3) {
        setBoardingState(profile.boarding_state);
      } else {
        setBoardingState(2);
      }
    } else {
      setBoardingOpen(false);
    }
  }, [selectedRole, profile, profile.boarding_state,window.location.pathname]);

  const onChange = (key, value) => {
    if (key === 'business-name') {
      if (validateBuisnessName(value)) {
        setBusinessName(value.toLowerCase());
      }
    }
    if (key === 'technology') {
      setTechnology(value);
    }
    if (key === 'material-name') {
      setMaterialName(value);
    }
    if (key === 'price') {
      setPrice(value);
    }
    if (key === 'location-name') {
      setLocationName(value);
    }
    if (key === 'payment-method-name') {
      setPaymentMethodName(value);
    }
  };
  const onNextClick = async () => {
    try {
      let data = { boarding_state: boardingState + 1 };
      if (boardingState === 1) {
        data.business_name = businessName;
      }
      if (boardingState === 2) {
        const result = await createMaterial({
          data: {
            name: technology,
            is_active: true,
            material_type: materialType,
          },
        });
        if (result) {
          setMaterialId(result._id);
        }
      }
      if (boardingState === 3) {
        await createColor({
          data: {
            code: 'rgba(125,125,125,1)',
            // delivery_time: '',
            density: 1,
            description: 'description',
            is_default: false,
            name: materialName,
            starting_price: 0,
            unit_price: price,
            images: [],
            is_active: true,
          },
          id: materialId,
        });
      }
      if (boardingState === 4) {
        await createLocation({
          name: locationName,
          address: 'add your address',
          postal_code: 'add your postal code',
          phone: 'add your phone',
          country_id: 0,
          country_name: 'not set',
          state_id: 0,
          state_name: 'not set',
          city_id: 0,
          city_name: 'not set',
          is_active: true,
          pickup_is_active: true,
        });
      }
      if (boardingState === 5) {
        await createPaymentMethod({
          name: paymentMethodName,
          description: 'description',
          type: 'manual',
          is_active: true,
          cod: false,
        });
      }
      await updateUserProfile(data);
      if (boardingState === 1) {
        getUserProfileContext();
      }
      setBoardingState(boardingState + 1);
    } catch (error) {
      updateToasterContext({
        type: 'error',
        message: boardingState === 1 ? 'This name is already taken' : 'Something went wrong',
      });
    }
  };

  return (
    <Modal open={boardingOpen} toggle={setBoardingOpen}>
      <Wrapper open={boardingOpen}>
        <Hello
          display={boardingState === 1 && boardingFirstLaunch}
          setBoardingFirstLaunch={setBoardingFirstLaunch}
          boardingOpen={boardingOpen}
        />
        <Step1
          onChange={onChange}
          display={boardingState === 1 && !boardingFirstLaunch}
          businessName={businessName}
        />
        <Step2
          onChange={onChange}
          display={boardingState === 2}
          technology={technology}
          materialType={materialType}
          setMaterialType={setMaterialType}
        />
        <Step3
          onChange={onChange}
          display={boardingState === 3}
          materialName={materialName}
          price={price}
        />
        <Step4 onChange={onChange} display={boardingState === 4} locationName={locationName} />
        <Step5
          onChange={onChange}
          display={boardingState === 5}
          paymentMethodName={paymentMethodName}
        />
        <Step6
          display={boardingState === 6}
          businessName={profile.business_name}
          setBoardingOpen={setBoardingOpen}
        />
        <Stepper
          boardingState={boardingState}
          display={
            (boardingState === 1 && !boardingFirstLaunch) ||
            (boardingState !== 1 && boardingState !== 6)
          }
          onNextClick={onNextClick}
          deActive={
            (boardingState === 1 && !businessName) ||
            (boardingState === 2 && !technology) ||
            (boardingState === 3 && !materialName) ||
            (boardingState === 4 && !locationName) ||
            (boardingState === 5 && !paymentMethodName)
          }
        />
      </Wrapper>
    </Modal>
  );
}

export default Boarding;
