import axios from 'axios';
const {  REACT_APP_ORDERS_SERVICE_URL,REACT_APP_UPLOAD_SERVICE_URL } =
  process.env;


export const getRecentConversations = async (  ) => {
    const response = await axios
        .get(`${REACT_APP_ORDERS_SERVICE_URL}/admin/form/recent-conversations`)
        .then((response) => response.data.payload);
    return response;
};
//get /admin/form/:ownerId/messages
export const getMessagesByOwnerId = async (ownerId) => {
    const response = await axios
       .get(`${REACT_APP_ORDERS_SERVICE_URL}/admin/form/${ownerId}/messages`)
       .then((response) => response.data.payload);
    return response;
};
export const getAttachmentsByFormId = async (formId) => {
  const response = await axios
    .get(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment/by-from`, {
      params: { formId }
    })
    .then((response) => response.data.payload);
  return response;
};